
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddStockRegisterModal from "@/components/modals/forms/AddStockRegisterModal.vue";
import AddProductListSalesEnquiry from "@/components/modals/forms/AddProductListSalesEnquiry.vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";
import JwtService from "@/core/services/JwtService";
import { Modal } from "bootstrap";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import moment from "moment";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddStockRegisterModal,
    AddProductListSalesEnquiry,
    // MixedWidget7,
    // Datatable,
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(false);
    const searchTearm = ref("");

    interface WIJournal {
      active: boolean;
      product_batch_no: string;
      invoice_no: string;
      invoice_date: string;
      uom_id: number;
      opening_qunatity: string;
      rate: string;
      other_charges: string;
      cost_to_company: string;
      material_condition_id: number;
      stock_remark: string;
      company_name: string;
      branch_name: string;
      product_name: string;
    }

    interface WICompanies {
      company_id: number;
      user: {
        avatar: string;
        name: string;
        group: string;
      };
      comp_type: string;
      status: {
        label: string;
        color: string;
      };
      active: {
        label: string;
        color: string;
      };
      roc: string;
      nob: string;
      doi: string;
      pan: string;
    }
    

    interface PurchaseReportList{
      purchase_invoice_no: string;
      purchase_invoice_date: string;
      supplier_company_name: string;
      payable_amount: string;
      paid_amount: string;
      // Total_payable_amount: string;
      // Total_paid_amount: string;
    }

    const formData = ref({
      startDate: "",
      endDate: "",
    });

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      mmaxPageg: 1,
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      //   getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      //getCompanyList(searchTearm.value);
    };

    function onDateClear(){
      if(!formData.value.startDate){
        tableData.value = ([]);
        sum.value = '';
        sum_paid.value = '';
        paginationData.value['start'] = 0
        paginationData.value['end'] = 0
        paginationData.value['total'] = 0
      }
      else(formData.value.startDate && tableData.value == ([]))
      {
        console.log("second if condition")
        sum.value = '';
        sum_paid.value = '';
        paginationData.value['start'] = 0
        paginationData.value['end'] = 0
        paginationData.value['total'] = 0
      }
      
    }

    // var tableData = ref<Array<WIJournal>>([]);
    // var tableData = ref([
    //   {
    //     date: "04/07/2022",
    //     supplie_name: "MetalXeperts",
    //     items: "Coils",
    //     amount_payable: "4000",
    //   },
    //   {
    //     date: "01/02/2022",
    //     supplie_name: "JSW",
    //     items: "TMT",
    //     amount_payable: "2000",
    //   },
    //   {
    //     date: "03/06/2022",
    //     supplie_name: "TataSteel",
    //     items: "Coils",
    //     amount_payable: "5000",
    //   },
    //   {
    //     date: "12/05/2022",
    //     supplie_name: "Vedanta",
    //     items: "TMT",
    //     amount_payable: "4000",
    //   },
    // ]);

    var resultsM = ref<Array<PurchaseReportList>>([]);
    var tableData = ref<Array<PurchaseReportList>>([]);
    const sum = ref();
    const sum_paid = ref();
   const getCompanyList = async () => {
      //   loadingData.value = true;
      refreshData()
       try {
        console.log('paginationData.value');
        console.log(paginationData.value);
        var values = { 
            "from_date": moment(formData.value.startDate[0]).format("YYYY-MM-DD"),
            "to_date": moment(formData.value.startDate[1]).format("YYYY-MM-DD"),
            "page"  :  (paginationData.value['activePage']), 
            "records_per_page" : parseInt(paginationData.value['perPage']) }

        await store.dispatch(ActionsFi.CUST_PURCHASE_LEDGER_REPORT, values).then(({ data }) => {
          console.log(values);
          
          tableData.value = ([]);
           if(data != 0){
          // set pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          //endpagination
         
          
          var status_label = ""
          var status_color = ""
          var active_label = ""
          var active_color = ""    
          // var sum_of_tot_payable : any[] = []
          // var sum_of_tot_paid : any[] = []
          
          for (let j = 0; j < data.result_list.length; j++) {

            var data_list: any[] = []
            var data_list1: any[] = []
            var data_list2: any[] = []
            var data_list3: any[] = []
            var data_list4: any[] = []
            
            // sum_of_tot_payable.push(parseFloat(data.result_list[j]['total_payable_amount']))
            // sum_of_tot_paid.push(parseFloat(data.result_list[j]['total_paid_amount']))
            
            // for (var i = 0; i < data.result_list[j].product_data.length; i++)
            // {
            //   data_list.push(data.result_list[j].product_data[i].product_name)
            //   data_list1.push(data.result_list[j].product_data[i].product_qunatity)
            //   data_list2.push(data.result_list[j].product_data[i].product_rate)
            //   data_list3.push(data.result_list[j].product_data[i].product_total)
            // }
            resultsM.value = Array({
              purchase_invoice_no: data.result_list[j]['purchase_invoice_no'],
              supplier_company_name: data.result_list[j]['supplier_company_name'],
              product_details: data.result_list[j]['product_data'],
              // product_name: data_list,
              // product_qunatity: data_list1,
              // product_rate: data_list2,
              // product_total:data_list3,
              payable_amount: data.result_list[j]['payable_amount'],
              paid_amount: data.result_list[j]['paid_amount'],
              purchase_invoice_date: data.result_list[j]['purchase_invoice_date'],
              
            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }
              sum.value =  data.Total_payable_amount
              sum_paid.value = data.Total_paid_amount
          
          loadingData.value = false;
           }
        }).catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };

    // onUpdated(() => {          });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WIJournal>>([]);

    onMounted(async () => {
       // await getCompanyList();
      setCurrentPageBreadcrumbs("Purchase Ledger Report", []);
      //   initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const product_add_modal = (sales_enquiry_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_add_product_sales_enquiry")
      );
      modal.show();
      (document.querySelector("#sales_enquiry_id") as HTMLInputElement).value =
        sales_enquiry_id;
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      //getCompanyList(search.value);
      console.log(search.value);
      // tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      // if (search.value !== "") {
      //   let results: Array<WICompanies> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };
    
    return {
      onDateClear,
      tableData,
      search,
      searchItems,
      checkedCompany,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      product_add_modal,
      formData,
      sum,
      sum_paid
    };
  },
});
